import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";

import ScrollToTop from "../components/ScrollToTop";
import {page as pageRoutes} from "../routes";

import ReactGA from "react-ga4";
if (window.location.hostname !== "localhost" && window.location.hostname !== "login.iism-local.de") {
  ReactGA.initialize("G-5GSVYE9C1Y");
}
const childRoutes = (Layout, routes) =>
    routes.map(({ children, path, component: Component }, index) =>
                   children ? (
                       // Route item with children
                       children.map(({ path, component: Component }, index) => (
                           <Route
                               key={index}
                               path={path}
                               exact
                               render={props => (
                                   <Layout>
                                       <Component {...props} />
                                   </Layout>
                               )}
                           />
                       ))
                   ) : (
                       // Route item without children
                       <Route
                           key={index}
                           path={path}
                           exact
                           render={props => (
                               <Layout>
                                   <Component {...props} />
                               </Layout>
                           )}
                       />
                   )
    );
const Routes = () => (
  <Router>
    <ScrollToTop>
      <Switch>
          {childRoutes(AuthLayout, pageRoutes)}
      </Switch>
    </ScrollToTop>
  </Router>
);

export default Routes;
