import React from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input, FormFeedback, FormText
} from "reactstrap";
import { withRouter } from '../withRouter';

class SetNewPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSend: false,
      userExist: false,
      mailExist: false,
      error: false,
      user: null,
      password: '',
      passwordValid: '',
      validate: {
        passwordState: '',
        passwordValidState: '',
      },
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  validatePasswort(e) {
    const { validate } = this.state;

    if (e.target.value.length >= 8) {
      validate.passwordState = 'has-success';
    } else {
      validate.passwordState = 'has-danger';
    }
  }

  validatePasswortValid(e) {
    const { validate, password } = this.state;

    if (e.target.value.length >= 8 && password === e.target.value) {
      validate.passwordValidState = 'has-success';
      validate.passwordState = 'has-success';
    } else {
      validate.passwordValidState = 'has-danger';
      validate.passwordState = 'has-danger';
    }
  }

  render() {
    const { password, passwordValid } = this.state;
    let headerHasFail = window.location.search
    let isFail = false
    if (headerHasFail.indexOf("fail") > -1)
      isFail = true
    let mailIsSend = false
    if (headerHasFail.indexOf("fail") > -1)
      mailIsSend = true
    return (
      <>
        <div className="text-center mt-4">
          <h1 className="h2">Passwort Zurücksetzen</h1>
          <p className="lead">Hier haben Sie die möglichkeit Ihr Password zurück zusetzen</p>
        </div>
        <Card>
          <CardBody>
            <div className="m-sm-4">
              <Form method={"post"} action={"/new-password" + window.location.search}>

                <FormGroup>
                  <Label>Passwort</Label>
                  <Input
                    bsSize="lg"
                    type="password"
                    name="password"
                    placeholder="Passwort eingeben"
                    value={password}
                    valid={this.state.validate.passwordState === "has-success"}
                    invalid={this.state.validate.passwordState === "has-danger"}
                    onChange={(e) => {
                      this.validatePasswort(e);
                      this.handleChange(e);
                    }}
                  />
                  <FormFeedback>
                    Ihr Passwort ist zu kurz
                  </FormFeedback>
                  <FormText>Mindestens 8 Zeichen.</FormText>
                </FormGroup>
                <FormGroup>
                  <Label>Passwort Wiederholen</Label>
                  <Input
                    bsSize="lg"
                    type="password"
                    name="passwordValid"
                    placeholder="Passwort Wiederholen"
                    value={passwordValid}
                    valid={this.state.validate.passwordValidState === "has-success"}
                    invalid={this.state.validate.passwordValidState === "has-danger"}
                    onChange={(e) => {
                      this.validatePasswortValid(e);
                      this.handleChange(e);
                    }}
                  />
                  <FormFeedback>
                    Ihr Passwort ist zu kurz oder nicht gleich
                  </FormFeedback>
                  <FormText>Mindestens 8 Zeichen.</FormText>
                </FormGroup>
                <div className="text-center mt-3">
                  <Button color="danger" size="lg" type={"submit"}>
                    Passwort zurücksetzen
                  </Button>
                </div>
              </Form>
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default withRouter(SetNewPassword);
