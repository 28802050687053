
// Auth
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import IsVerify from "../pages/auth/IsVerify";
import IsNotVerify from "../pages/auth/IsNotVerify";
import ForgotPassword from "../pages/auth/ForgotPassword";
import HashNotExist from "../pages/auth/HashNotExist";
import SetNewPassword from "../pages/auth/SetNewPassword";
import NewPasswortIsSet from "../pages/auth/newPasswortIsSet";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500"


const IsNotVerifyRoutes = {
  path: "/canNotVerify",
  name: "Login",
  component: IsNotVerify,
  children: null
};
const IsVerifyRoutes = {
  path: "/isVerify",
  name: "Login",
  component: IsVerify,
  children: null
};
const authRoutes = {
  path: "/",
  name: "Login",
  component: SignIn,
  children: null
};
const oAuthRoutes = {
  path: "/authenticate",
  name: "Login",
  component: SignIn,
  children: null
};
const oAuthRegRoutes = {
  path: "/authenticate/register",
  name: "register",
  component: SignUp,
  children: null
};
const authRoutesReg = {
  path: "/register",
  name: "register",
  component: SignUp,
  children: null
};
const forgotPassword = {
  path: "/forgot-password",
  name: "forgotPassword",
  component: ForgotPassword,
  children: null
};
const setNewPasword = {
  path: "/setNewPassword",
  name: "setNewPassword",
  component: SetNewPassword,
  children: null
};
const newPasswordIsSet = {
  path: "/newPasswordIsSet",
  name: "setNewPassword",
  component: NewPasswortIsSet,
  children: null
};
const noHash = {
  path: "/hashNotExist",
  name: "hashNotExist",
  component: HashNotExist,
  children: null
};






// Auth specific routes
export const page = [
  newPasswordIsSet,
  IsVerifyRoutes,
  IsNotVerifyRoutes,
  authRoutes,
  oAuthRoutes,
  authRoutesReg,
  oAuthRegRoutes,
  forgotPassword,
  setNewPasword,
  noHash
];

// All routes
export default [
  authRoutes,
];
