import { SET_CLIENT } from '../constants';

export function loadClient(client) {
  return {
    type: SET_CLIENT,
    payload: { client },
  };
}

export const fetchClient = (c = "iism") => {
  c === null ? c = "iism" : c = c
  return async (dispatch) => {
    try {
      const response = await fetch("/getClient/" + c.replace("\"", "").replace("\"", ""));
      const dataResult = await response.json();
      dispatch(loadClient(dataResult));
    }
    catch (e) {
      console.log(e);
    }
  };
};

