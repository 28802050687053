import { useParams, useLocation } from "react-router-dom";
import React, { Component }  from 'react';
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
export const withRouter = (WrappedComponent) => (props) => {
  const params = useParams();
  const location = useLocation();
  let query = useQuery();

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <WrappedComponent {...props} params={params} location={location} query={query} />
  );
};
