import React from "react";
import { Button, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';

export default () => {
  return (
    <>
      <Card>
        <CardBody>
          <div className="text-center mt-4 text-white">
            <h2>Ihr Passwort wurde geändert</h2>
            <Link to="/">
              <Button color="success" size="lg">
                Jetzt Anmelden
              </Button>
            </Link>
          </div>
        </CardBody>
      </Card>
    </>
  )
}
