import React from "react";

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Input,
  Label,
  UncontrolledAlert,
  FormFeedback,
  FormText
} from "reactstrap";
import { withRouter } from '../withRouter';

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSend: false,
      userExist: false,
      mailExist: false,
      error: false,
      user: null,
      email: '',
      password: '',
      passwordValid: '',
      validate: {
        emailState: '',
        passwordState: '',
        passwordValidState: '',
      },
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  validateEmail(e) {
    const emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const { validate } = this.state;

    if (emailRex.test(e.target.value)) {
      validate.emailState = 'has-success';
    } else {
      validate.emailState = 'has-danger';
    }

    this.setState({ validate });
  }

  validateUsername(e) {
    const { validate } = this.state;

    if (e.target.value.length >= 8) {
      validate.usernameState = 'has-success';
    } else {
      validate.usernameState = 'has-danger';
    }
  }

  validatePasswort(e) {
    const { validate } = this.state;

    if (e.target.value.length >= 8) {
      validate.passwordState = 'has-success';
    } else {
      validate.passwordState = 'has-danger';
    }
  }

  validatePasswortValid(e) {
    const { validate, password } = this.state;

    if (e.target.value.length >= 8 && password === e.target.value) {
      validate.passwordValidState = 'has-success';
      validate.passwordState = 'has-success';
    } else {
      validate.passwordValidState = 'has-danger';
      validate.passwordState = 'has-danger';
    }
  }

  async submitForm(e) {
    const { validate, email, password, passwordValid } = this.state;
    e.preventDefault();
    if (email !== "" && password !== "" && passwordValid !== "" &&
      validate.emailState !== "has-danger" && validate.passwordState !== "has-danger" && validate.passwordValidState !== "has-danger") {
      console.log(`Email: ${email}`);
      this.setState({isSend: true})
      let host = "https://login.iism.de"
      let pathname = this.props.location.pathname === "/authenticate/register" ? "/registerSmartHome" : "/register";
      let pathnameLogin = this.props.location.pathname === "/authenticate/register" ? "/authenticate" : "/";
      if (window.location.host.indexOf("iism-local") > -1)
        host = "https://login.iism-local.de:3000"

      let newUser = await fetch(host + pathname, {
          method: 'post',
          body: JSON.stringify({ password, username: email }),
          headers: {'Content-Type': 'application/json'}})
      newUser = await newUser.json()
      this.setState(newUser)

      if (newUser.userExist === false) {
        setTimeout(() => {
          window.location.href = pathnameLogin
        }, 3000)
      }
    } else {
      console.log("fail");
    }
  }

  render() {
    const { email, password, username, passwordValid, isSend, userExist, user, mailExist } = this.state;
    let host = "https://login.iism.de"
    let pathname = this.props.location.pathname === "/authenticate/register" ? "/registerSmartHome" : "/register";
    if (window.location.host.indexOf("iism-local") > -1)
      host = "https://login.iism-local.de:3000"
    let headerHasFail = window.location.search
    let success = false
    if (headerHasFail.indexOf("fail") > -1)
      success = true
    return (
      <React.Fragment>
        <div className="text-center mt-4">
          <h1 className="h2">Registrieren</h1>
        </div>
        <Card>
          <CardBody>
            <small>
              <Button color="light" size="lg" onClick={this.props.history.goBack}>
                zurück
              </Button>
            </small>
            {isSend &&
              <>
                {userExist &&
                  <>
                    <p>Benutzername existiert bereits</p>
                    <br/>
                    <Button color="success" size="lg" onClick={() => {
                    this.setState({error: false, userExist: false, user: null, isSend: false, mailExist: false})}}>
                      Daten Ändern
                    </Button>
                  </>
                }
                {mailExist &&
                  <>
                    <p>E-Mail existiert bereits</p>
                    <br/>
                    <Button color="success" size="lg" onClick={() => {
                      this.setState({error: false, userExist: false, user: null, isSend: false, mailExist: false})}}>
                      Daten Ändern
                    </Button>
                  </>
                }
                {user !== null &&
                  <>
                    <p>Dankeschön {user.username} für Ihre Registrierung, bitte Überprüfen Sie Ihre E-Mails ({user.email}) für die Verifizierung Ihrer E-Mail Adresse.</p>
                    <p>Sie werden in 3 Sekunden Weitergeleitet....</p>
                  </>
                }
              </>
            }
            {!isSend &&
              <>
                <div className="m-sm-4">
                  <Form action={host + pathname} method={"post"} onSubmit={(e) => this.submitForm(e)}>
                    <FormGroup>
                      <Label>E-Mail</Label>
                      <Input
                        bsSize="lg"
                        type="email"
                        name="email"
                        placeholder="E-Mail eingeben"
                        valid={this.state.validate.emailState === "has-success"}
                        invalid={this.state.validate.emailState === "has-danger"}
                        value={email}
                        onChange={(e) => {
                          this.validateEmail(e);
                          this.handleChange(e);
                        }}
                      />
                      <FormFeedback>
                        Oh oh! Anscheinend gibt es ein Problem mit Ihrer E-Mail. Bitte eingeben
                        eine korrekte E-Mail.
                      </FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label>Passwort</Label>
                      <Input
                        bsSize="lg"
                        type="password"
                        name="password"
                        placeholder="Passwort eingeben"
                        value={password}
                        valid={this.state.validate.passwordState === "has-success"}
                        invalid={this.state.validate.passwordState === "has-danger"}
                        onChange={(e) => {
                          this.validatePasswort(e);
                          this.handleChange(e);
                        }}
                      />
                      <FormFeedback>
                        Ihr Passwort ist zu kurz
                      </FormFeedback>
                      <FormText>Mindestens 8 Zeichen.</FormText>
                    </FormGroup>
                    <FormGroup>
                      <Label>Passwort Wiederholen</Label>
                      <Input
                        bsSize="lg"
                        type="password"
                        name="passwordValid"
                        placeholder="Passwort Wiederholen"
                        value={passwordValid}
                        valid={this.state.validate.passwordValidState === "has-success"}
                        invalid={this.state.validate.passwordValidState === "has-danger"}
                        onChange={(e) => {
                          this.validatePasswortValid(e);
                          this.handleChange(e);
                        }}
                      />
                      <FormFeedback>
                        Ihr Passwort ist zu kurz oder nicht gleich
                      </FormFeedback>
                      <FormText>Mindestens 8 Zeichen.</FormText>
                    </FormGroup>
                    {success &&
                      <UncontrolledAlert color="success">
                        Benutzer wurde Registriert
                      </UncontrolledAlert>
                    }
                    <div className="text-center mt-3">
                      <Button color="danger" size="lg" type={"submit"}>
                        Registrieren
                      </Button>
                    </div>
                    <br/>
                    <a href={"https://www.iism.de/info/impressum"}>
                      Impressum
                    </a>&nbsp;&nbsp;
                    <a href={"https://www.iism.de/info/datenschutz"}>
                      Datenschutz
                    </a>
                  </Form>
                </div>
              </>
            }
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default withRouter(SignUp);
