import React from "react";
import { Button, Card, CardBody } from 'reactstrap';

export default () => {
  return (
    <>
      <Card>
        <CardBody>
          <div className="text-center mt-4 text-white">
            <h2>Verifizierung Erfolgreich</h2>
            <p>E-Mail wurde Verifiziert</p>
            <Button color="success" size="lg" onClick={() => {
              window.location.href = "/"}}>
              Jetzt Anmelden
            </Button>
          </div>
        </CardBody>
      </Card>
    </>
  )
}
