import React from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import { withRouter } from '../withRouter';

class ForgotPassword extends React.Component {
  render() {
    console.log(this.props)
    return (
      <>
        <div className="text-center mt-4">
          <h1 className="h2">Passwort Vergessen</h1>
          <p className="lead">Bitte geben Sie Ihre E-Mail an und Sie bekommen einen Link zum zurücksetzten Ihres Passworts.</p>
        </div>
        <Card>
          <CardBody>
            <div className="m-sm-4">
              <small>
                <Button color="light" size="lg" onClick={this.props.history.goBack}>
                  zurück
                </Button>
              </small>
              <Form method={"post"} action={"/forgot-password"}>
                <FormGroup>
                  <Label>E-Mail</Label>
                  <Input
                    bsSize="lg"
                    type="email"
                    name="email"
                    placeholder="Bitte geben Sie Ihre E-Mail Adresse ein"
                  />
                </FormGroup>
                <div className="text-center mt-3">
                  <Button color="primary" size="lg" type={"submit"}>
                    Passwort zurücksetzten
                  </Button>
                </div>
              </Form>
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default withRouter(ForgotPassword);
